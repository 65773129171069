﻿/**
*   RyanCV - Resume/CV/vCard Theme (Jekyll)
*   Version: 1.0
*   Author: beshleyua
*   Author URL: https://themeforest.net/user/beshleyua
*   Copyright © RyanCV by beshleyua. All Rights Reserved.
**/


/* TABLE OF CONTENTS
	1. Base
	2. Container
	3. Columns
	4. Preloader
	5. Typography
	6. Forms
	7. Alignments
	8. Animations
*/


/*
	1. Basic
*/

html {
	margin-right: 0!important;
}

body {
	margin: 0;
	padding: 0;
	border: none;
	font-family: $default-font;
	font-size: $default-size;
	color: $default-color;
	background: $white-color;
	letter-spacing: $default-letter-spacing;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;

	@media (max-width: 1199px) {
		overflow: visible;
	}
}

.page {
	overflow: hidden;
	position: relative;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1023px) {
		overflow: visible;
	}
	@media (max-width: 1199px) {
		height: auto;
		display: block;
	}
}

* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	background-color: transparent;
}

::-webkit-scrollbar {
	width: 6px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0,0,0,0.5);
	-webkit-border-radius: 6px;
	border-radius: 6px;
}

/*
	2. Container
*/

.container {
	margin: 8vh 6vw;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 84vh;
	max-width: 1268px;
	max-height: 674px;
	@include transform(translateX(0px));
	@include transition(all 0.4s ease 0s);

	&.opened {
		@include transform(translateX(0px));
	}
	@media (max-width: 1072px) {
		margin: -300px 0 0 -220px;
		width: 440px;
		&.opened {
			@include transform(translateX(-204px));
		}
	}
	@media (max-width: 1023px) {
		margin: 0 auto;
		position: relative;
		top: 83px;
		left: 0;
		width: auto;
		max-width: 540px;
		height: auto;
		@include transform(none);
		&.opened {
			@include transform(none);
		}
	}
	@media (max-height: 700px) {
		margin-top: 3vh;
		margin-bottom: 3vh;
		height: 94vh;
	}
	@media (max-height: 740px) {
		margin-top: 6vh;
		margin-bottom: 6vh;
		height: 88vh;
	}
	@media (max-width: 1300px) {
		margin: 5vh 3vw;
		height: 90vh;
	}
	@media (max-width: 1199px) {
		margin: 0 auto;
		padding: 0 15px 0 100px;
		position: relative;
		top: 20px;
		left: 0;
		width: auto;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;
		max-height: initial;
		height: auto;
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		-o-transform: none;

		&.opened {
			transform: none;
			-webkit-transform: none;
			-moz-transform: none;
			-o-transform: none;
			margin-left: auto;
			margin-right: auto;
		}
	}
	@media (max-width: 560px) {
		margin: 0;
		top: 0;
		width: auto;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 60px 0 0 0;
		max-width: 400px;

		&.opened {
			margin-left: auto;
			margin-right: auto;
			padding-top: 150px!important;
		}
	}
}

.background {
	position: fixed;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: $extra-active-color;
}


/*
	3. Columns
*/

.row {
	margin: 0 -30px;
	position: relative;
	font-size: 0;

	.col {
		padding: 30px;
		position: relative;
		display: inline-block;
		vertical-align: top;
		font-size: $default-size;

		.col {
			padding-top: 0;
			padding-bottom: 20px;
		}
		@media (min-width: 1024px) {
			&.col-d-12 {
				width: 99.996%;
			}
			&.col-d-11 {
				width: 91.663%;
			}
			&.col-d-10 {
				width: 83.333%;
			}
			&.col-d-9 {
				width: 74.997%;
			}
			&.col-d-8 {
				width: 66.664%;
			}
			&.col-d-7 {
				width: 58.331%;
			}
			&.col-d-6 {
				width: 49.998%;
			}
			&.col-d-5 {
				width: 41.665%;
			}
			&.col-d-4 {
				width: 33.332%;
			}
			&.col-d-3 {
				width: 24.999%;
			}
			&.col-d-2 {
				width: 16.666%;
			}
			&.col-d-1 {
				width: 8.333%;
			}
		}
		@media (max-width: 1023px) {
			&.col-t-12 {
				width: 99.996%;
			}
			&.col-t-11 {
				width: 91.663%;
			}
			&.col-t-10 {
				width: 83.333%;
			}
			&.col-t-9 {
				width: 74.997%;
			}
			&.col-t-8 {
				width: 66.664%;
			}
			&.col-t-7 {
				width: 58.331%;
			}
			&.col-t-6 {
				width: 49.998%;
			}
			&.col-t-5 {
				width: 41.665%;
			}
			&.col-t-4 {
				width: 33.332%;
			}
			&.col-t-3 {
				width: 24.999%;
			}
			&.col-t-2 {
				width: 16.666%;
			}
			&.col-t-1 {
				width: 8.333%;
			}
		}
		@media (max-width: 560px) {
			&.col-m-12 {
				width: 99.996%;
			}
			&.col-m-11 {
				width: 91.663%;
			}
			&.col-m-10 {
				width: 83.333%;
			}
			&.col-m-9 {
				width: 74.997%;
			}
			&.col-m-8 {
				width: 66.664%;
			}
			&.col-m-7 {
				width: 58.331%;
			}
			&.col-m-6 {
				width: 49.998%;
			}
			&.col-m-5 {
				width: 41.665%;
			}
			&.col-m-4 {
				width: 33.332%;
			}
			&.col-m-3 {
				width: 24.999%;
			}
			&.col-m-2 {
				width: 16.666%;
			}
			&.col-m-1 {
				width: 8.333%;
			}
		}
		@media (max-width: 1199px) {
			&.col-t-12 {
				width: 99.996%;
			}
			&.col-t-11 {
				width: 91.663%;
			}
			&.col-t-10 {
				width: 83.333%;
			}
			&.col-t-9 {
				width: 74.997%;
			}
			&.col-t-8 {
				width: 66.664%;
			}
			&.col-t-7 {
				width: 58.331%;
			}
			&.col-t-6 {
				width: 49.998%;
			}
			&.col-t-5 {
				width: 41.665%;
			}
			&.col-t-4 {
				width: 33.332%;
			}
			&.col-t-3 {
				width: 24.999%;
			}
			&.col-t-2 {
				width: 16.666%;
			}
			&.col-t-1 {
				width: 8.333%;
			}
		}
		@media (max-width: 560px) {
			&.col-m-12 {
				width: 99.996%;
			}
			&.col-m-11 {
				width: 91.663%;
			}
			&.col-m-10 {
				width: 83.333%;
			}
			&.col-m-9 {
				width: 74.997%;
			}
			&.col-m-8 {
				width: 66.664%;
			}
			&.col-m-7 {
				width: 58.331%;
			}
			&.col-m-6 {
				width: 49.998%;
			}
			&.col-m-5 {
				width: 41.665%;
			}
			&.col-m-4 {
				width: 33.332%;
			}
			&.col-m-3 {
				width: 24.999%;
			}
			&.col-m-2 {
				width: 16.666%;
			}
			&.col-m-1 {
				width: 8.333%;
			}
		}
		&.border-line-v:first-child:before {
			display: none;
		}
	}
	&:after {
		content: '';
		display: block;
		clear: both;
	}
	&.border-line-v:before {
		display: none;
	}
}

.border-line-v {
	&:before {
		content: '';
		position: absolute;
		left: -1px;
		top: 0;
		width: 1px;
		height: 100%;
		background: -moz-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
		background: -webkit-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
		background: radial-gradient(ellipse at top, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
	}
}

.border-line-h {
	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background: -moz-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
		background: -webkit-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
		background: radial-gradient(ellipse at center, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
	}
}


/*
	4. Preloader
*/

.preloader {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: $white-color;
	z-index: 1000;
	.spinner {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 40px;
		.double-bounce1, .double-bounce2 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $active-color;
			opacity: 1;
			-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
			animation: sk-bounce 2.0s infinite ease-in-out;
			@include border-radius(50%);
		}
		.double-bounce2 {
			-webkit-animation-delay: -1.0s;
			animation-delay: -1.0s;
			opacity: 0.1;
		}
	}
}


/*
	5. Typography
*/

h1, h2, h3, h4, h5, h6 {
	margin: 0 0 5px 0;
	font-family: $default-font;
	font-size: $h1-size;
	color: $dark-color;
	line-height: $h1-size+8;
	font-weight: 600;
}

h2 {
	font-size: $h2-size;
}

h3 {
	font-size: $h3-size;
}

h4 {
	font-size: $h4-size;
}

h5 {
	font-size: $h5-size;
}

h6 {
	font-size: $h6-size;
}

p {
	font-size: $default-size;
	line-height: $line-height;
	padding: 0;
	margin: 0 0 25px 0;
}

strong {
	font-weight: 600;
}

/* links, buttons */
.lnks {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 70px;
	z-index: 10;
	display: flex;
	flex-direction: row;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 1px;
		background: -moz-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
		background: -webkit-radial-gradient(center, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
		background: radial-gradient(ellipse at center, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
	}
}

.lnk, .button {
	display: block;
	position: relative;
	float: none;
	width: 100%;
	height: 70px;
	line-height: 70px;
	font-size: $extra-small-size;
	color: $dark-color;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	border: none;

	&:before {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 1px;
		height: 100%;
		background: -moz-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
		background: -webkit-radial-gradient(top, ellipse cover, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
		background: radial-gradient(ellipse at top, #dddddd 0%, rgba(255, 255, 255, 0) 70%);
	}
	.ion, .arrow {
		margin: 0 0 0 8px;
		position: relative;
		top: -2px;
		display: inline-block;
		vertical-align: middle;
		font-size: $small-icon-size;
		@include transition(all 0.3s ease 0s);
	}
	.arrow {
		top: -1px;
		width: 14px;
		height: 2px;

		&:before, &:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: $dark-color;
			@include transition(all 0.3s ease 0s);
		}
		&:after {
			left: auto;
			top: auto;
			right: 0;
			bottom: 3px;
			width: 8px;
			height: 2px;
			@include transform(rotate(45deg));
		}
	}
	.text {
		@include transition(all 0.3s ease 0s);
	}
	&:last-child {
		&:before {
			display: none;
		}
	}
	&:hover {
		color: $active-color;
		border: none;

		.ion {
			color: $active-color;
		}
		.arrow {
			&:before, &:after {
				background: $active-color;
			}
		}
	}
}

.button {
	padding: 0 30px;
	display: inline-block;
	float: none;
	width: auto;
	height: 50px;
	line-height: 50px;
	background: #eeeeee;
	border-radius: 4px;

	.text {
		padding-left: 14px;
	}

	&:before {
		display: none;
	}
}

a { 
	color: $default-color;
	text-decoration: none;
	opacity: 1;
	outline: none;
	&:hover {
		text-decoration: none;
		color: $active-color;
	}
}

/* Lists */
ul {
	list-style: disc;
	margin-top: 25px;
	margin-bottom: 25px;
	padding-left: 0px;
	list-style-position: inside;
}

ol {
	list-style: decimal;
	margin-top: 25px;
	margin-bottom: 25px;
	padding-left: 20px;
}

ul ul, ol ol, ul ol, ol ul {
	margin-top: 8px;
	margin-bottom: 8px;
	margin-left: 15px;
}

/* Code */
code {
	padding: 0 4px;
	font-style: italic;
	color: #999;
	text-decoration: none;
	display: inline-block;
	vertical-align: middle;
	overflow: auto;
	max-width: 100%;

	table {
		margin: 0;
	}
}

pre {
	margin: 25px 0;
	padding: 25px;
	max-width: 100%;
	overflow: auto;
	white-space: pre;

	& > code {
		display: block;
		padding: 0;
		font-style: normal;
		line-height: 1.8em;
	}
}

mark, ins {
	background: #eee;
	text-decoration: none;
}

figure {
	margin: 25px 0;
}

/* Tables */
table {
	width: 100%;
	margin: 30px 0;
	padding: 0;
	border-collapse: collapse;

	code, pre {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
}

table caption {
	padding: 6px 0;
	font-size: 12px;
}

th {
	padding: 10px 20px;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	font-size: 14px;
}

td {
	padding: 10px;
	font-size: 14px;
}

dt {
	margin-bottom: 5px;
	font-size: 14px;
	font-weight: 700;
}

dd {
	margin-top: 0;
	margin-bottom: 30px;
	margin-left: 30px;
}

/* blockquote */
blockquote {
	margin: 25px 0;
	padding: 0 20px;
	color: #888;
	font-style: italic;
	font-weight: 300;
	border-left: 2px solid #78cc6d;
}

blockquote code {
	padding: 2px;
	display: inline;
}

blockquote *:last-child {
	margin-bottom: 0;
}

blockquote p {
	margin-bottom: 10px;
}

blockquote cite {
	font-size: 12px;
}

/* iframes */
iframe, object {
	max-width: 100%;
	margin: 0 0 25px 0;
}

p {
	iframe {
		margin: 0;
	}
}


/*
	6. Forms
*/

input,
textarea,
button,
input[type="submit"] {
	margin: 0;
	padding: 0 15px;
	display: block;
	font-family: $default-font;
	font-size: $default-size;
	width: 100%;
	height: 50px;
	color: $dark-color;
	background: none;
	border: 2px solid #eeeeee;
	appearance: none;
	resize: none;
	outline: 0;
	@include transition(all 0.3s ease 0s);
	@include border-radius(0px);
	&:focus {
		color: $dark-color;
		border-bottom: $form-border-hover;
	}
}

textarea {
	padding: 15px;
	height: 94px;
}

button, input[type="submit"] {
	width: auto;
	display: inline-block;
	vertical-align: top;
	color: $dark-color;
	border-bottom: $form-border;
	text-align: left;
	cursor: pointer;

	&:hover {
		border-bottom: $form-border-hover;
	}
}

label, legend { 
	display: block;
	padding-bottom: 10px;
	font-family: $default-font;
	font-size: $default-size;
}

fieldset {
	border-width: 0; 
	padding: 0; 
}

input[type="checkbox"], 
input[type="radio"] { 
	display: inline; 
}

::-webkit-input-placeholder {
	color: $extra-color;
}

:-moz-placeholder { 
	color: $extra-color;
}

::-moz-placeholder {
	color: $extra-color;
}

:-ms-input-placeholder {  
	color: $extra-color;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
	color: $dark-color;
}

input:focus:-moz-placeholder, textarea:focus:-moz-placeholder { 
	color: $dark-color;
}

input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
	color: $dark-color;
}

input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {  
	color: $dark-color;
}

input.error, textarea.error {
	border: 2px solid $error-color!important;
}

label.error {
	display: none!important;
}

input.error::-moz-placeholder, 
textarea.error::-moz-placeholder {
	color: $error-color;
}

input.error:-moz-placeholder, 
textarea.error:-moz-placeholder {
	color: $error-color;
}

input.error:-ms-input-placeholder, 
textarea.error:-ms-input-placeholder {
	color: $error-color;
}

input.error::-webkit-input-placeholder, 
textarea.error::-webkit-input-placeholder {
	color: $error-color;
}

input:focus,
textarea:focus {
	color: $dark-color;
	border: 2px solid $active-color;
}

button {
	color: $dark-color;
	border-bottom: 1px solid #eeeeee;
	transition: none;
	-moz-transition: none;
	-webkit-transition: none;
	-o-transition: none;
}

input[type="submit"] {
	padding: 0 30px;
	border: 1px solid #eeeeee;
	height: 50px;
	line-height: 50px;
	cursor: pointer;
	transition: none;
	-moz-transition: none;
	-webkit-transition: none;
	-o-transition: none;
	background: #eee;
	border-radius: 4px;
	-webkit-border-radius: 4px;
}

input[type="checkbox"], input[type="radio"] {
	width: auto;
	height: auto;
	margin-right: 5px;
}

input[type="checkbox"] {
	-webkit-appearance: checkbox;
}

input[type="radio"] {
	-webkit-appearance: radio;
}

select {
	width: 100%;
	height: 50px;
}

input[type="file"] {
	padding: 0;
	border: none;
	height: auto;
}

/*
	7. Alignments
*/

.align-center {
	text-align: center !important;
}

.align-right {
	text-align: right !important;
}

.align-left {
	text-align: left !important;
}

.pull-right { 
	float: right !important;
}

.pull-left { 
	float: left !important; 
}

.pull-none { 
	float: none !important;
}

/* width */
.full-width {
	max-width: 100% !important;
	width: 100% !important; 
}

.full-max-width {
	max-width: 100% !important;
	width: auto !important;
}

/* vertical align */
.centrize {
	display: table !important;
	table-layout: fixed !important;
	height: 100% !important;
	position: relative !important;
}

.vertical-center {
	display: table-cell !important;
	vertical-align: middle !important;
}

.vertical-top {
	display: table-cell !important;
	vertical-align: top !important;
}

.vertical-bottom {
	display: table-cell !important;
	vertical-align: bottom !important;
}

/* text formating */
.text-uppercase {
	text-transform: uppercase !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-regular {
	font-weight: 400 !important;
}

.text-bold {
	font-weight: 700 !important;
}

.text-italic {
	font-style: italic !important;
}

/* clearing */
.clear {
	clear: both;
}

.h-title {
	margin-bottom: 5px;
}

/*
	8. Animations
*/

/* flip cards */
.FlipIn {
	-webkit-backface-visibility: visible !important;
	backface-visibility: visible !important;
	-webkit-animation-duration: 0.7s !important;
	animation-duration: 0.7s !important;
	-webkit-animation-name: FlipIn;
	animation-name: FlipIn;
}

@-webkit-keyframes FlipIn {
	from {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}
	60% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
		opacity: 1;
	}
	80% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}
	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}

@keyframes FlipIn {
	from {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}
	60% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
		opacity: 1;
	}
	80% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}
	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}

.FlipOut {
	-webkit-animation-duration: 0.7s !important;
	animation-duration: 0.7s !important;
	-webkit-backface-visibility: visible !important;
	backface-visibility: visible !important;
	-webkit-animation-name: FlipOut;
	animation-name: FlipOut;
}

@-webkit-keyframes FlipOut {
	from {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
	30% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
		opacity: 1;
	}
	to {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		opacity: 0;
	}
}

@keyframes FlipOut {
	from {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
	30% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
		opacity: 1;
	}
	to {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		opacity: 0;
	}
}

/* preloader */
@-webkit-keyframes sk-bounce {
	0%, 100% { 
		-webkit-transform: scale(0.0)
	}
	50% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bounce {
	0%, 100% { 
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	} 50% { 
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
}

@keyframes typed_cursor {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/*
	1. Gradient
*/

.background.gradient {
  background: rgba($active-color, 0.7);
  background: -webkit-linear-gradient(top left, desaturate(darken($active-color, 10%), 25%) 0%, $active-color 100%);
  background: linear-gradient(to bottom right, desaturate(darken($active-color, 10%), 25%) 0%, $active-color 100%);
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.1);
  bottom: -320px;
  -webkit-animation: square 50s infinite;
  animation: square 50s infinite;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-khtml-border-radius: 4px;
}

.bg-bubbles li:nth-child(1) {
  left: 10%;
}

.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 34s;
          animation-duration: 34s;
}

.bg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 120px;
  height: 120px;
  -webkit-animation-duration: 44s;
          animation-duration: 44s;
}

.bg-bubbles li:nth-child(5) {
  left: 70%;
}

.bg-bubbles li:nth-child(6) {
  left: 80%;
  width: 240px;
  height: 240px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 320px;
  height: 320px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 40px;
  height: 40px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 80s;
          animation-duration: 80s;
}

.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 80s;
          animation-duration: 80s;
}

.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 320px;
  height: 320px;
  -webkit-animation-delay: 22s;
          animation-delay: 22s;
}

@-webkit-keyframes square {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-1500px) rotate(600deg);
            transform: translateY(-1500px) rotate(600deg);
  }
}

@keyframes square {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-1500px) rotate(600deg);
            transform: translateY(-1500px) rotate(600deg);
  }
}